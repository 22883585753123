<!-- eslint-disable @angular-eslint/template/use-track-by-function  -->
<div class="branding flex-row flex-justify-space-between flex-align-center">
  <div class="flex-row flex-justify-space-between flex-align-center">
    <a (click)="sidenav.close()" routerLink="home">
      <img [src]="homeLogo" [alt]="homeLogo" id="sidenavLogo" />
    </a>
    @if (
      authLoginState === AuthLoginState.LOGGEDHARD ||
      authLoginState === AuthLoginState.LOGGEDSOFT ||
      authLoginState === AuthLoginState.REFRESHING
      ) {
      @if (user) {
        <a class="username" (click)="sidenav.close()" routerLink="profile/edit">
          {{ user?.nome }} {{ user?.cognome }}
        </a>
      }
    }
    @if (
      authLoginState === AuthLoginState.NOTLOGGED || authLoginState === AuthLoginState.LOGGEDGUEST
      ) {
      <a class="username" (click)="openSignin()">
        {{ 'dottnet.menu.login' | translate }}
      </a>
    }
  </div>
  <button mat-icon-button (click)="sidenav.close()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<hr class="divisorio-menu" />
<div class="search flex-row">
  <mat-form-field class="flex-fill">
    <mat-label>{{ 'dottnet.menu.ricerca' | translate }} </mat-label>
    <input matInput type="text" minlength="3" [ngModel]="searchText" (ngModelChange)="searchText = $event"  (keydown.enter)="searchTextResult()" placeholder="{{ 'dottnet.search.toofewcharacters' | translate }}"/>
    <button matSuffix mat-icon-button aria-label="Search" (disabled)="!checkMinLength()"  (click)="searchTextResult()">
      <mat-icon>search</mat-icon>
    </button>
    <mat-error>{{ 'dottnet.search.toofewcharacters' | translate }}</mat-error>
  </mat-form-field>
</div>

<mat-nav-list>
  @for (item of navigation; track item; let i = $index) {
    @if (item.subLinks) {
      @if (i > 0) {
        <hr class="divisorio-menu" />
      }
      <a mat-list-item (click)="sidenav.close()" [routerLink]="[item.link]" routerLinkActive="active" class="rootLink">
        {{ item.label | translate }}
      </a>
      <hr class="divisorio-menu" />
      @if (user?user.premium:false && item.link === 'extra') {
        @for (subItem of item.subLinksLogged; track subItem; let i = $index) {
          @if (i > 0) {
            <hr class="divisorio-menu" />
          }
          <a mat-list-item (click)="sidenav.close()" [routerLink]="[subItem.link]" routerLinkActive="active" class="subLink">
            {{ subItem.label | translate }}
          </a>
        }
      }
      @if (user?user.premium:false && item.link === 'extra') {
        @for (subItem of item.subLinks; track subItem; let i = $index) {
          @if (i > 0) {
            <hr class="divisorio-menu" />
          }
          <a mat-list-item (click)="sidenav.close()" [routerLink]="[subItem.link]" routerLinkActive="active" class="subLink">
            {{ subItem.label | translate }}
          </a>
        }
      }
      @if (item.link !== 'extra') {
        @for (subItem of item.subLinks; track subItem; let i = $index) {
          @if (i > 0) {
            <hr class="divisorio-menu" />
          }
          <a mat-list-item (click)="sidenav.close()" [routerLink]="[subItem.link]" routerLinkActive="active" class="subLink">
            {{ subItem.label | translate }}
          </a>
        }
      }
    }
    @if (!item.subLinks) {
      <hr class="divisorio-menu" />
      <a mat-list-item (click)="sidenav.close()" [routerLink]="[item.link]" routerLinkActive="active" class="rootLink">
        {{ item.label | translate }}
      </a>
    }
  }

  <!-- <a
  mat-list-item
  *ngFor="let item of navigationSideMenu"
  (click)="sidenav.close()"
  [routerLink]="[item.link]"
  routerLinkActive="active"
  >
  {{ item.label | translate }}
</a> -->
</mat-nav-list>
@if (
  authLoginState === AuthLoginState.LOGGEDHARD ||
  authLoginState === AuthLoginState.LOGGEDSOFT ||
  authLoginState === AuthLoginState.REFRESHING
  ) {
  <div class="bottomNav flex-row">
    <button mat-flat-button color="accent" (click)="onLogoutClick(); sidenav.close()" class="flex-fill">
      {{ 'dottnet.menu.logout' | translate }}
    </button>
  </div>
}
