import {
	Component,
	ChangeDetectionStrategy,
	Input,
	OnInit,
	OnDestroy,
	ChangeDetectorRef,
	PLATFORM_ID,
	Inject
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

import { filter, Observable, Subscription } from 'rxjs';
import { AuthLoginState } from '../../core/auth/auth.models';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'dottnet-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    imports: [TranslateModule]
})
export class FooterComponent implements OnInit, OnDestroy {
	@Input() authLoginState$: Observable<AuthLoginState>;

	isUserLogged: boolean = false;

	// Subs
	userLoggedSub: Subscription;

	constructor(
		@Inject(PLATFORM_ID) private platformId: Object,
		private changeDetector: ChangeDetectorRef
	) {}

	ngOnInit(): void {
		if (isPlatformBrowser(this.platformId)) {
			this.userLoggedSub = this.authLoginState$
				.pipe(filter((authLoginState) => !!authLoginState))
				.subscribe((authLoginState) => {
					this.isUserLogged = [AuthLoginState.LOGGEDHARD, AuthLoginState.LOGGEDSOFT].includes(
						authLoginState
					);
					this.changeDetector.detectChanges();
				});
		}
	}

	ngOnDestroy(): void {
		if (this.userLoggedSub) this.userLoggedSub.unsubscribe();
	}
}
