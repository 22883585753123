<div mat-dialog-title class="flex-row flex-justify-space-between flex-align-center desktop-dialog">
  <img id="dialogLogoDN" (click)="goToContext()" [src]="infoLogoDn" [alt]="'logo'" />
  <div class="dialog-title">{{ getPermissionTitle() }}</div>
  @if (showClose) {
    <button mat-icon-button (click)="goToContext()">
      <fa-icon class="dn-icon" [icon]="getCrossIcon()"></fa-icon>
    </button>
  }
  @if (!showClose) {
    <button mat-icon-button>
    </button>
  }
</div>
<div mat-dialog-title class="flex-row flex-justify-space-between flex-align-center mobile-dialog">
  @if (showClose) {
    <button mat-icon-button (click)="goToContext()">
      <fa-icon class="dn-icon" [icon]="getChevronLeftIcon()"></fa-icon>
    </button>
  }
  @if (!showClose) {
    <button mat-icon-button>
    </button>
  }
  <div class="dialog-title">{{ getPermissionTitle() }}</div>
  <img id="dialogLogoDN" (click)="goToContext()" [src]="infoLogoDn" [alt]="'logo'" />
</div>

<hr class="dialog-title-separator" />

<div class="flex-col">
  <section class="login-form">
    @if (
      [
      PermissionVisualizationType.Signin,
      PermissionVisualizationType.UserSessionExpired
      ].includes(permissionType)
      ) {
      <mat-dialog-content>
        <dottnet-form-signin (formEventEmitter)="onPost($event)" [permissionType]="permissionType" (closeDialogEmitter)="closeDialog()"></dottnet-form-signin>
      </mat-dialog-content>
    }

    @if (permissionType === PermissionVisualizationType.UserNotActivated) {
      <mat-dialog-content>
        <dottnet-mail-confirm (sendMailEvent)="sendMail()" [user$]="user$"></dottnet-mail-confirm>
      </mat-dialog-content>
    }

    @if (
      permissionType !== PermissionVisualizationType.Signin &&
      permissionType !== PermissionVisualizationType.UserSessionExpired &&
      permissionType !== PermissionVisualizationType.UserNotActivated
      ) {
      <mat-dialog-content>
        <p>
          {{ getPermissionBody() }}
        </p>
      </mat-dialog-content>
    }
  </section>
</div>